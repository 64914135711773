import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import SectionList from 'pages/Section/SectionList';
import SearchBox from 'components/SearchBox';
import BaseSelect from 'components/BaseSelect';
import { Card, CardHeader, CardBody, Badge } from 'reactstrap';
import { useHistory } from 'react-router';
import { SERVICE_PROVIDER } from 'core/constants';

function Home(props) {
  const { t } = useTranslation();
  const { resource, updateFullLoading, updateNotification, updateUser } = props;
  const [searchText, setSearchText] = useState('');
  const [resourceLists, setResourceLists] = useState([]);
  const [serviceProviderList, setServiceProviderList] = useState([]);
  const [serviceProviderSelected, setServiceProviderSelected] = useState('');
  const history = useHistory();

  useEffect(() => {
    filterResourceList(resource.resources.data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText, resource, serviceProviderSelected]);

  useEffect(() => {
    let serviceProviderList = [
      {
        value: SERVICE_PROVIDER.AWS_MNG_CONSOLE,
        label: t('common.label.awsMngConsole')
      },
      {
        value: SERVICE_PROVIDER.LYSITHEA_PC,
        label: t('common.label.lysitheaPC')
      },
      {
        value: SERVICE_PROVIDER.LYSITHEA_SP,
        label: t('common.label.lysitheaSP')
      },
      {
        value: SERVICE_PROVIDER.TIME_SHEET,
        label: t('common.label.timeSheet')
      },
      {
        value: SERVICE_PROVIDER.WIKI,
        label: t('common.label.samlWiki')
      }
    ];

    setServiceProviderList(serviceProviderList);
  }, [t]);
  const filterResourceList = data => {
    const searchTextInstance = searchText
      ? searchText
          .toString()
          .slice(0)
          .trim()
      : '';
    let items = searchTextInstance.length
      ? data.filter(({ name }) => name.toLowerCase().indexOf(searchTextInstance.toLowerCase()) > -1)
      : data;
    items = !serviceProviderSelected
      ? items
      : items.filter(({ serviceProviderType }) => serviceProviderType === serviceProviderSelected);

    const timeSheetList = [],
      previewTimeSheetList = [],
      lysitheaPCList = [],
      previewLysitheaPCList = [],
      lysitheaSPList = [],
      previewLysitheaSPList = [],
      wikiList = [],
      awsList = [],
      otherResourcesList = [];
    items.forEach(item => {
      if (item.serviceProviderType === SERVICE_PROVIDER.TIME_SHEET) {
        if ('PREVIEW' === item.environment) {
          previewTimeSheetList.push(item);
        } else timeSheetList.push(item);
      } else if (item.serviceProviderType === SERVICE_PROVIDER.LYSITHEA_PC) {
        if ('PREVIEW' === item.environment) {
          previewLysitheaPCList.push(item);
        } else lysitheaPCList.push(item);
      } else if (item.serviceProviderType === SERVICE_PROVIDER.LYSITHEA_SP) {
        if ('PREVIEW' === item.environment) {
          previewLysitheaSPList.push(item);
        } else lysitheaSPList.push(item);
      } else if (item.serviceProviderType === SERVICE_PROVIDER.WIKI) wikiList.push(item);
      else if (item.serviceProviderType === SERVICE_PROVIDER.AWS_MNG_CONSOLE) awsList.push(item);
      else otherResourcesList.push(item);
    });

    const sortNameByAlphaBet = arr => {
      return arr.sort((a, b) => a.name.localeCompare(b.name));
    };

    const sortedDatas = [
      ...sortNameByAlphaBet(timeSheetList),
      ...sortNameByAlphaBet(previewTimeSheetList),
      ...sortNameByAlphaBet(lysitheaPCList),
      ...sortNameByAlphaBet(previewLysitheaPCList),
      ...sortNameByAlphaBet(lysitheaSPList),
      ...sortNameByAlphaBet(previewLysitheaSPList),
      ...sortNameByAlphaBet(wikiList),
      ...sortNameByAlphaBet(awsList),
      ...sortNameByAlphaBet(otherResourcesList)
    ];
    setResourceLists(sortedDatas);
  };

  function handleSearch(searchText) {
    setSearchText(searchText);
  }

  const handleChange = value => {
    setServiceProviderSelected(value);
  };

  return (
    <>
      <h3>
        {t('menu.home')} <Badge className="ml-1 align-text-bottom">{resourceLists.length}</Badge>
      </h3>
      <Card>
        <CardHeader className="card-header-toolbar">
          <div style={{ width: '300px' }} className="float-left m-r-5 m-w-100 m-m-b-10">
            <SearchBox
              placeholder={t('common.placeholder.searchBy', {
                field: t('common.label.fieldName', { field: t('common.label.resource') })
              })}
              value={searchText}
              handleSearch={searchText => handleSearch(searchText)}
            />
          </div>
          <div style={{ width: '360px' }} className="float-left m-w-100">
            <BaseSelect
              value={serviceProviderSelected}
              options={serviceProviderList}
              placeholder={t('common.placeholder.select', {
                field: t('common.label.applicationType')
              })}
              onChange={handleChange}
              clearable={true}
              disabled={false}
              multiple={false}
              sortable={false}
              allowSort={false}
            />
          </div>
        </CardHeader>
        <CardBody className="p-b-0">
          <SectionList
            t={t}
            resources={resourceLists}
            updateFullLoading={updateFullLoading}
            updateUser={updateUser}
            updateNotification={updateNotification}
            history={history}
            initialized={resource.resources.initialized}
            userInfo={props.userInfo}
          />
        </CardBody>
      </Card>
    </>
  );
}

export default Home;
