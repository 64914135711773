import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, ButtonGroup, Card, CardHeader, CardBody, Badge } from 'reactstrap';
import { useHistory } from 'react-router';
import { section as sectionService } from 'services';
import { logger } from 'core/logger';
import { errorUtils } from 'core/utils';
import { toast } from 'react-toastify';
import SectionList from './SectionList';
import { BaseSelect, SearchBox } from 'components';
import { objUtils } from 'core/utils';
import { SERVICE_PROVIDER } from 'core/constants';

function Section(props) {
  const { t } = useTranslation();
  const {
    resource,
    updateFullLoading,
    updateNotification,
    updateResourceModal,
    updateUser,
    addSection,
    match: {
      params: { sectionId }
    }
  } = props;
  const [section, setSection] = useState({});
  const [searchText, setSearchText] = useState('');
  const [resourceLists, setResourceLists] = useState([]);
  const [serviceProviderList, setServiceProviderList] = useState([]);
  const [serviceProviderSelected, setServiceProviderSelected] = useState('');
  const [initialized, setInitialized] = useState(false);
  const history = useHistory();

  useEffect(() => {
    filterResourceList(section);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText, resource, serviceProviderSelected, section]);

  useEffect(() => {
    let serviceProviderList = [
      {
        value: SERVICE_PROVIDER.AWS_MNG_CONSOLE,
        label: t('common.label.awsMngConsole')
      },
      {
        value: SERVICE_PROVIDER.LYSITHEA_PC,
        label: t('common.label.lysitheaPC')
      },
      {
        value: SERVICE_PROVIDER.LYSITHEA_SP,
        label: t('common.label.lysitheaSP')
      },
      {
        value: SERVICE_PROVIDER.TIME_SHEET,
        label: t('common.label.timeSheet')
      },
      {
        value: SERVICE_PROVIDER.WIKI,
        label: t('common.label.samlWiki')
      }
    ];

    setServiceProviderList(serviceProviderList);
  }, [t]);

  useEffect(() => {
    async function fetchData() {
      try {
        updateFullLoading(true);
        const sectionData = await sectionService.getSectionById(sectionId);
        setSection(sectionData);
        setServiceProviderSelected('');
        setSearchText('');
      } catch (err) {
        logger.error('fetchData -> err', err);
        const { response } = err;
        const { data = {} } = response || {};
        let code = data.code;
        if (code === 'section-not-found') {
          return history.push('/page-not-found');
        }
        errorUtils.handleError(err, toast, updateNotification, updateUser, history, t);
      } finally {
        updateFullLoading(false);
        setInitialized(true);
      }
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sectionId]);

  const handleSearch = searchText => {
    setSearchText(searchText);
  };

  const handleBack = () => {
    history.push('/');
  };

  const handleDelete = () => {
    updateNotification({
      showModal: true,
      heading: t('common.message.confirm.header'),
      noCancel: false,
      displayText: t('common.message.confirmAction', { action: t('common.button.delete').toLowerCase() }),
      type: 'confirm',
      buttonOkText: t('common.button.yes'),
      buttonCloseText: t('common.button.no'),
      callback: async closeModal => {
        closeModal();
        try {
          updateFullLoading(true);
          await sectionService.deleteSection(section.sectionId);
          const sections = await sectionService.getSections();
          addSection(sections);
          toast.success(t('common.message.delete.item.success'));
          setTimeout(() => {
            handleBack();
          }, 1000);
        } catch (err) {
          logger.error('handleDelete -> err', err);
          errorUtils.handleError(err, toast, updateNotification, updateUser, history, t);
        } finally {
          updateFullLoading(false);
        }
      }
    });
  };

  const handleEdit = () => {
    updateResourceModal({
      showModal: true,
      sectionId: section.sectionId,
      sectionName: section.sectionName,
      resourceIds: section.resourceIds
    });
  };

  const filterResourceList = data => {
    const searchTextInstance = searchText
      ? searchText
          .toString()
          .slice(0)
          .trim()
      : '';
    const resourceMap =
      resource && resource.resources.data.length ? objUtils.arrayToObject(resource.resources.data) : {};
    console.log('resourceMap -> ', resourceMap);
    let items =
      Object.keys(resourceMap).length && data.resourceIds && data.resourceIds.length
        ? data.resourceIds.filter(id => resourceMap.hasOwnProperty(id)).map(id => resourceMap[id])
        : [];
    items = searchTextInstance.length
      ? items.filter(({ name }) => name.toLowerCase().indexOf(searchTextInstance.toLowerCase()) > -1)
      : items;
    items = !serviceProviderSelected
      ? items
      : items.filter(({ serviceProviderType }) => serviceProviderType === serviceProviderSelected);
    const arrTimeSheet = [],
      arrLysitheaPc = [],
      arrLysitheaSp = [],
      arrWiki = [],
      arrAWS = [],
      arrOrther = [];
    items.forEach(item => {
      if (item.serviceProviderType === SERVICE_PROVIDER.TIME_SHEET) arrTimeSheet.push(item);
      else if (item.serviceProviderType === SERVICE_PROVIDER.LYSITHEA_PC) arrLysitheaPc.push(item);
      else if (item.serviceProviderType === SERVICE_PROVIDER.LYSITHEA_SP) arrLysitheaSp.push(item);
      else if (item.serviceProviderType === SERVICE_PROVIDER.WIKI) arrWiki.push(item);
      else if (item.serviceProviderType === SERVICE_PROVIDER.AWS_MNG_CONSOLE) arrAWS.push(item);
      else arrOrther.push(item);
    });

    const sortNameByAlphaBet = arr => {
      return arr.sort((a, b) => a.name.localeCompare(b.name));
    };

    const sortedDatas = [
      ...sortNameByAlphaBet(arrTimeSheet),
      ...sortNameByAlphaBet(arrLysitheaPc),
      ...sortNameByAlphaBet(arrLysitheaSp),
      ...sortNameByAlphaBet(arrWiki),
      ...sortNameByAlphaBet(arrAWS),
      ...sortNameByAlphaBet(arrOrther)
    ];
    setResourceLists(sortedDatas);
  };

  return (
    <>
      <h3>
        {section.sectionName} <Badge className="ml-1 align-text-bottom">{resourceLists.length}</Badge>
      </h3>
      <Card>
        <CardHeader className="card-header-toolbar">
          <div style={{ width: '300px' }} className="float-left m-r-5 xs-full-width m-m-b-10">
            <SearchBox
              placeholder={t('common.placeholder.searchBy', {
                field: t('common.label.fieldName', { field: t('common.label.resource') })
              })}
              value={searchText}
              handleSearch={searchText => handleSearch(searchText)}
            />
          </div>
          <div style={{ width: '360px' }} className="float-left xs-full-width m-m-b-10">
            <BaseSelect
              value={serviceProviderSelected}
              options={serviceProviderList}
              placeholder={t('common.placeholder.select', {
                field: t('common.label.applicationType')
              })}
              onChange={value => setServiceProviderSelected(value)}
              clearable={true}
              disabled={false}
              multiple={false}
              sortable={false}
              allowSort={false}
            />
          </div>
          <div className="float-right xs-float-left">
            <ButtonGroup>
              <Button color="default" onClick={() => handleEdit()} title={t('common.button.edit')}>
                <span className="material-icons">edit</span>
              </Button>{' '}
              <Button
                color="default"
                onClick={() => handleDelete()}
                title={t('common.button.delete')}
                className="xs-m-l-5"
              >
                <span className="material-icons">delete_outline</span>
              </Button>
            </ButtonGroup>
          </div>
        </CardHeader>
        <CardBody className="p-b-0">
          <SectionList
            t={t}
            resources={resourceLists}
            updateUser={updateUser}
            updateFullLoading={updateFullLoading}
            updateNotification={updateNotification}
            history={history}
            initialized={initialized}
            userInfo={props.userInfo}
          />
        </CardBody>
      </Card>
    </>
  );
}

export default Section;
