import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CardTable } from 'components';
import { toast } from 'react-toastify';
import { resource as resourceService, user as userService } from 'services';
import { cookies } from 'core/utils';
import { COOKIE_KEY_USER_TOKEN } from 'core/constants';
import { errorUtils } from 'core/utils';
import { logger } from 'core/logger';
import { Modal, ModalHeader, ModalBody, Button } from 'reactstrap';
import moment from 'moment-timezone';
import { getResourceImage } from 'core/helpers';
import PropTypes from 'prop-types';
class Paragraph extends React.Component {
  static propTypes = { content: PropTypes.string };
  static defaultProps = { content: '' };
  render = () => <p className={''} dangerouslySetInnerHTML={{ __html: this.props.content }} />;
}

const spin = (
  <div className="loadingTable">
    <div className="lds-ellipsis">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
);

function SectionList(props) {
  const { t } = useTranslation();
  const { resources, initialized = true, updateNotification, updateFullLoading, updateUser, history } = props;
  const [selectedResources, setSelectedResources] = useState([]);
  const [selectedResourceToAccess, setSelectedResourceToAccess] = useState(null);
  const [showAliasAccountsModal, setShowAliasAccountsModal] = useState(false);

  const onSelectResources = id => {
    let list = selectedResources;
    if (list.includes(id)) {
      list = selectedResources.filter(_id => _id !== id);
    } else {
      list = [...list, id];
    }
    setSelectedResources(list);
  };

  const onClickResources = async (item, aliasAccountLoginId = null) => {
    try {
      const idpid = item && item.samlresources && item.samlresources.idpid ? item.samlresources.idpid : '';
      const { id, action, serviceProviderType, environment } = item;
      updateFullLoading(true);
      if (action !== 2) {
        return;
      }
      await resourceService.verifyPolicy(id);
      let userToken = cookies.getKey(COOKIE_KEY_USER_TOKEN);
      const userInfo = props.userInfo;
      const { isAliasAccountLogin } = userInfo;

      const supportResources = ['lysithea_pc', 'lysithea_sp', 'time_sheet'].includes(item.serviceProviderType);
      const timezoneBrowser = moment.tz.guess();
      // case alias account access ['lysithea_pc', 'lysithea_sp', 'time_sheet']: will be access with parent user
      if (supportResources && isAliasAccountLogin) {
        const params = {
          loginId: userInfo.parentLoginId,
          timezoneBrowser
        };
        const getUserTokenByAliasAccount = await userService.generateTokenAliasAccount(params);
        userToken = getUserTokenByAliasAccount.userToken;
      }
      // case alias account access !['lysithea_pc', 'lysithea_sp', 'time_sheet']: will be access with selected access user
      else if (!supportResources && aliasAccountLoginId && aliasAccountLoginId !== userInfo.loginId) {
        const params = {
          loginId: aliasAccountLoginId,
          timezoneBrowser
        };
        const getUserTokenByAliasAccount = await userService.generateTokenAliasAccount(params);
        userToken = getUserTokenByAliasAccount.userToken;
      }
      if (idpid) {
        const data = await resourceService.getInitSaml(idpid, userToken);
        const { context, entityEndpoint } = data;
        const form = document.createElement('form');
        form.setAttribute('action', entityEndpoint);
        form.setAttribute('method', 'POST');
        form.setAttribute('id', 'saml-form');
        form.setAttribute('target', '_blank');
        const input = document.createElement('input');
        input.setAttribute('type', 'hidden');
        input.setAttribute('id', 'SAMLResponse');
        input.setAttribute('name', 'SAMLResponse');

        input.setAttribute('value', context);
        form.appendChild(input);
        document.body.appendChild(form);
        document.forms[0].submit();
        document.body.removeChild(form);
      } else if (['lysithea_pc', 'lysithea_sp', 'time_sheet'].includes(serviceProviderType)) {
        let url =
          'PREVIEW' === environment
            ? serviceProviderType === 'lysithea_pc'
              ? process.env.REACT_APP_PREVIEW_LYSITHEA_PC_URL
              : serviceProviderType === 'lysithea_sp'
              ? process.env.REACT_APP_PREVIEW_LYSITHEA_SP_URL
              : process.env.REACT_APP_PREVIEW_LYSITHEA_TIME_SHEET_URL
            : serviceProviderType === 'lysithea_pc'
            ? process.env.REACT_APP_LYSITHEA_PC_URL
            : serviceProviderType === 'lysithea_sp'
            ? process.env.REACT_APP_LYSITHEA_SP_URL
            : process.env.REACT_APP_LYSITHEA_TIME_SHEET_URL;
        // create url
        if (['lysithea_pc', 'lysithea_sp'].includes(serviceProviderType)) {
          url = `${url}&token=${userToken}`;
        } else if ('time_sheet' === serviceProviderType) {
          url = `${url}?token=${userToken}`;
        }
        // redirect to url
        return url;
      }
    } catch (err) {
      logger.error('goToResource -> err', err);
      const { response } = err;
      const { data = {} } = response || {};
      let code = data.code;
      if (err.error) {
        code = err.error;
      }
      if (['resource-not-found', 'permission_denied'].includes(code)) {
        toast.warning(t('common.message.resource.notFound2'));
      } else if (code === 'out-of-working-hours') {
        toast.warning(t('common.message.policy.outOfWorkingHour'));
      } else {
        errorUtils.handleError(err, toast, updateNotification, updateUser, history, t);
      }
    } finally {
      updateFullLoading(false);
    }
  };

  const onCardClick = async item => {
    const samlresources = item.samlresources;
    if (samlresources) {
      const { actionType, url } = samlresources || {};
      if (actionType === 'URL') {
        window.open(url);
        return;
      }
    }
    const userInfo = props.userInfo;
    setSelectedResourceToAccess(item);
    if (
      (!userInfo.isAliasAccountLogin && (!userInfo.aliasLoginFlag || userInfo.aliasAccounts.length <= 0)) ||
      ['lysithea_pc', 'lysithea_sp', 'time_sheet', 'non_saml'].includes(item.serviceProviderType)
    )
      await handleRedirectToResource(item);
    else setShowAliasAccountsModal(true);
  };

  const redirectToResourceByAliasAccount = async AliasAccountloginId => {
    setShowAliasAccountsModal(false);
    await handleRedirectToResource(selectedResourceToAccess, AliasAccountloginId);
  };

  const handleRedirectToResource = async (item, aliasAccountLoginId = null) => {
    const { userInfo = {} } = props;
    const { isAliasAccountLogin } = userInfo;
    const allowRedirect = ['lysithea_pc', 'lysithea_sp', 'time_sheet'].includes(item.serviceProviderType);
    // case: login with alias account
    if (isAliasAccountLogin && allowRedirect) aliasAccountLoginId = null;
    let _windowReference = allowRedirect ? window.open() : null;
    await onClickResources(item, aliasAccountLoginId).then(url => {
      if (url && url !== '' && allowRedirect) {
        _windowReference.location = url;
        _windowReference.opener = null;
        _windowReference.parent = null;
        _windowReference.rel = 'noreferrer';
      } else if (_windowReference) _windowReference.close();
    });
  };

  const renderResourceList = () => {
    let accountsToAccess = [];
    let { userInfo = {} } = props;
    let { aliasAccounts = [], parentLoginId, loginId } = userInfo;
    if (Object.keys(userInfo).length) {
      const { isAliasAccountLogin } = userInfo;
      if (isAliasAccountLogin)
        accountsToAccess = [{ loginId: userInfo.parentLoginId }, { loginId: userInfo.loginId }, ...aliasAccounts];
      else accountsToAccess = [{ loginId: userInfo.loginId }, ...aliasAccounts];
    }
    if (!initialized) {
      return spin;
    }
    let resourceImageType = null,
      iconURL = null,
      description = null,
      selectedResourceName = null;
    if (selectedResourceToAccess) {
      const { serviceProviderType, samlresources, environment, name } = selectedResourceToAccess;
      selectedResourceName = name;
      iconURL = selectedResourceToAccess?.iconURL;
      description = selectedResourceToAccess?.description;
      resourceImageType = ['lysithea_pc', 'lysithea_sp', 'time_sheet'].includes(serviceProviderType)
        ? 'PREVIEW' === environment
          ? `preview_${serviceProviderType}`
          : serviceProviderType
        : serviceProviderType;
    }
    const _parentLoginId = parentLoginId ? parentLoginId : loginId;
    if (resources.length) {
      return (
        <>
          <CardTable
            tasks={resources}
            selectedTasks={selectedResources}
            onSelectCard={id => onSelectResources(id)}
            onClickCard={item => onCardClick(item)}
            ableToSelect={false}
          />
          <Modal
            isOpen={showAliasAccountsModal}
            centered
            zIndex={2050}
            modalClassName="modal-choose-alias-accounts"
            size="md"
          >
            <ModalHeader toggle={() => setShowAliasAccountsModal(false)}>
              <img src={iconURL || getResourceImage(resourceImageType)} />
              <span>{t('modal.select.alias')}</span>
            </ModalHeader>
            <ModalBody>
              <div className="modal-description">
                <h3>{t('modal.choose.an.alias')}</h3>
                <Paragraph content={t('modal.continue.to.resource', { resource: selectedResourceName })} />
              </div>
              {accountsToAccess.map(({ loginId }) => {
                return (
                  <div key={loginId} className="account-select">
                    <Button onClick={() => redirectToResourceByAliasAccount(loginId)}>
                      <div className={`alias-avatar-item ${loginId === _parentLoginId && 'main-avatar-item'}`}>
                        <div className="avatar-content"></div>
                        <span>{loginId}</span>
                      </div>
                    </Button>
                  </div>
                );
              })}
            </ModalBody>
          </Modal>
        </>
      );
    } else {
      return (
        <div className="p-b-20 m-auto text-center text-muted d-block">
          <span className="material-icons md-36">error_outline</span>
          <div className="d-block mt-1">{t('common.label.no_data_text')}</div>
        </div>
      );
    }
  };

  return <div className="resource-list d-flex flex-wrap p-b-0">{renderResourceList()}</div>;
}

export default SectionList;
