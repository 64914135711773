import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { errorUtils } from 'core/utils';
import { useHistory } from 'react-router';
import { logger } from 'core/logger';
import { BaseInput, BaseLabelCounter, LabelTooltip } from 'components';
import profilePlaceholder from 'assets/images/avatars/user-avatar.png';
import { FormFeedback } from 'reactstrap';
import fb from 'core/firebase';
import { user as userService } from 'services';

function Profile(props) {
  const { t } = useTranslation();
  const { updateNotification, updateFullLoading, currentUser: originalUser, updateUser } = props;
  const history = useHistory();
  const [avatarHover, setAvatarHover] = useState(false);
  const [avatar, setAvatar] = useState(originalUser.profileURL);
  const [avatarFile, setAvatarFile] = useState(null);
  const [firstName, setFirstName] = useState(originalUser.firstName);
  const [lastName, setLastName] = useState(originalUser.lastName ? originalUser.lastName : '');
  const [loginId] = useState(originalUser.loginId);
  const [errFirstName, setErrFirstName] = useState('');
  const [errEmployeeId, setErrEmployeeId] = useState('');
  const preventEdit = !originalUser.isEditUserProfile;

  const handleSubmit = async e => {
    e.preventDefault();
    try {
      updateFullLoading(true);
      if (!validate()) {
        toast.warning(t('common.message.invalidInputs'));
        return;
      }
      // Change avatar
      let avatarProfileURL = '';
      if (avatarFile) {
        await fb.uploadProfileImage(originalUser.id, avatarFile).then(profileURL => (avatarProfileURL = profileURL));
      }

      // Update profile
      const updatingData = {
        firstName: firstName.trim(),
        lastName: lastName ? lastName.trim() : '',
        profileURL: avatarProfileURL ? avatarProfileURL : originalUser.profileURL
      };
      await userService.updateUserProfile(updatingData);
      const newUserInfo = await userService.getUser();
      setFirstName(newUserInfo.firstName);
      setLastName(newUserInfo.lastName);
      updateUser(newUserInfo);
      setAvatar(newUserInfo.profileURL);
      toast.success(t('profile.message.updated.ok'));
    } catch (err) {
      logger.error('handleSubmit -> err', err);
      const { response } = err;
      const { data = {} } = response || {};
      let code = data.code;
      if (err.error) {
        code = err.error;
      }
      if (code === 'user-not-found') {
        toast.warning(t('common.message.user.notFound'));
      } else {
        errorUtils.handleError(err, toast, updateNotification, updateUser, history, t);
      }
    } finally {
      updateFullLoading(false);
    }
  };

  const onAvatarHover = () => setAvatarHover(true);
  const onAvatarNoHover = () => setAvatarHover(false);

  const onChangeImage = async event => {
    const file = event.target.files[0];
    event.target.value = '';
    if (!file.type.match('image.*')) {
      toast.warning(t('common.message.upload.image.error'));
      return;
    }
    const reader = new FileReader();
    reader.onload = async e => {
      setAvatar(e.target.result);
      setAvatarFile(file);
    };
    reader.readAsDataURL(file);
  };

  const onChange = name => value => {
    switch (name) {
      case 'firstName':
        setFirstName(value.substr(0, 30));
        if (!value) {
          setErrFirstName('common.validator.isRequired');
        } else {
          setErrFirstName('');
        }
        break;
      case 'lastName':
        setLastName(value.substr(0, 30));
        break;
      default:
        break;
    }
  };

  const validate = () => {
    let result = true;
    if (!firstName) {
      setErrFirstName('common.validator.isRequired');
      result = false;
    }
    if (!originalUser.employeeId) {
      setErrEmployeeId('common.validator.isRequired');
      result = false;
    }
    return result;
  };

  const resetForm = () => {
    setAvatar(originalUser.profileURL);
    setAvatarFile(null);
    setFirstName(originalUser.firstName);
    setLastName(originalUser.lastName);
    setErrFirstName('');
    setErrEmployeeId('');
    localStorage.removeItem('mfaRetry');
  };

  const renderUpdateProfile = () => {
    return (
      <div>
        <form onSubmit={handleSubmit}>
          {/* profile avatar */}
          <div className="form-group row m-b-20">
            <div className="col-12 col-lg-3 text-lg-right m-t-5">
              <LabelTooltip text={t('profile.profileImage.label')} tip={t('profile.profileImage.label.tooltip')} />
            </div>
            <div className="col-12 col-lg-9">
              <div className="web-uploader clearfix">
                <label
                  onMouseEnter={onAvatarHover}
                  onMouseLeave={onAvatarNoHover}
                  className="image-item"
                  style={{ width: 150, height: 150, position: 'relative', cursor: 'pointer' }}
                >
                  <img src={avatar ? avatar : profilePlaceholder} width="100%" height="100%" alt="avatar" />
                  <div
                    style={{
                      position: 'absolute',
                      right: 0,
                      bottom: 0,
                      left: 0,
                      paddingTop: '8px',
                      paddingBottom: '8px',
                      backgroundColor: 'RGBA(0,0,0,1)',
                      opacity: avatarHover ? 0.7 : 0,
                      cursor: 'pointer',
                      color: 'white',
                      textAlign: 'center',
                      transition: 'opacity .2s ease-in-out',
                      margin: 0
                    }}
                  >
                    <input
                      type="file"
                      name="companyIcon"
                      accept="image/gif, image/jpeg, image/png"
                      onChange={onChangeImage}
                      hidden
                    />
                    <i className="material-icons">camera_alt</i>
                  </div>
                </label>
              </div>
            </div>
          </div>

          {/* Employee ID */}
          <div className="form-group row justify-content-center m-b-20">
            <div className="col-12 col-lg-3 text-lg-right m-t-5">
              <BaseLabelCounter label={t('profile.employeeId.label')} showCounter={false} />
            </div>
            <div className="col-12 col-lg-9">
              <BaseInput disabled type="text" value={originalUser.employeeId ? originalUser.employeeId : ''} />
              <FormFeedback className="d-block">
                {errEmployeeId ? t(errEmployeeId, { label: t('profile.employeeId.label') }) : ''}
              </FormFeedback>
            </div>
          </div>
          {/* login ID */}
          <div className="form-group row justify-content-center m-b-20">
            <div className="col-12 col-lg-3 text-lg-right m-t-5">
              <BaseLabelCounter label={t('profile.loginId.label')} showCounter={false} />
            </div>
            <div className="col-12 col-lg-9">
              <BaseInput disabled type="text" value={loginId ? loginId.replace('@trans-cosmos.co.jp', '') : ''} />
            </div>
          </div>
          {/* last name */}
          <div className="form-group row justify-content-center m-b-20">
            <div className="col-12 col-lg-3 text-lg-right m-t-5">
              <BaseLabelCounter label={t('profile.lastName.label')} value={lastName} length={30} required={false} />
            </div>
            <div className="col-12 col-lg-9">
              <BaseInput
                type="text"
                maxLength={30}
                placeholder={t('placeholder.input.Enter', { field: t('profile.lastName.label') })}
                value={lastName ? lastName : ''}
                disabled={preventEdit}
                onChange={onChange('lastName')}
              />
            </div>
          </div>
          {/* first name */}
          <div className="form-group row justify-content-center m-b-20">
            <div className="col-12 col-lg-3 text-lg-right m-t-5">
              <BaseLabelCounter label={t('profile.firstName.label')} value={firstName} length={30} />
            </div>
            <div className="col-12 col-lg-9">
              <BaseInput
                type="text"
                maxLength={30}
                placeholder={t('placeholder.input.Enter', { field: t('profile.firstName.label') })}
                value={firstName ? firstName : ''}
                disabled={preventEdit}
                onChange={onChange('firstName')}
              />
              <FormFeedback className="d-block">
                {errFirstName ? t(errFirstName, { label: t('profile.firstName.label') }) : ''}
              </FormFeedback>
            </div>
          </div>

          <div className="row">
            <div className="col-12 col-lg-9 offset-lg-3">
              <div className="btn-group">
                <button
                  className="btn btn-primary"
                  type="submit"
                  disabled={
                    (!avatar || avatar === originalUser.profileURL) &&
                    firstName === originalUser.firstName &&
                    (lastName === originalUser.lastName || (!lastName && !originalUser.lastName))
                  }
                >
                  {t('common.button.save')}
                </button>
                &nbsp;
                <button className="btn btn-default" type="button" onClick={resetForm}>
                  {t('common.button.reset')}
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  };

  return renderUpdateProfile();
}

export default Profile;
