import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useTranslation } from 'react-i18next';

function ModalBox(props) {
  const { t } = useTranslation();
  const { updateNotification, notification } = props;

  const handleClose = () => {
    updateNotification({
      showModal: false,
      heading: '',
      displayText: '',
      callback: null,
      validStatus: null,
      callbackClose: null
    });
  };

  const callback = () => {
    notification.callback(handleClose);
  };
  const callbackClose = () => {
    notification.callbackClose(handleClose);
  };

  let { buttonOkText, buttonCloseText, noCancel } = notification;
  buttonOkText = noCancel ? t('common.button.ok') : buttonOkText ? buttonOkText : t('common.button.ok');
  buttonCloseText = buttonCloseText ? buttonCloseText : t('common.button.cancel');
  const closeText = notification.callback && notification.callback !== null ? buttonCloseText : t('common.button.ok');
  const isType = notification.hasOwnProperty('type') && notification.type !== null;
  return (
    <Modal isOpen={notification.showModal} modalClassName={`${props.className ? props.className : ''} modal-info`}>
      <ModalHeader>
        {isType && notification.type === 'warning' && (
          <i className="material-icons md-36 text-warning m-r-10">warning</i>
        )}
        {isType && notification.type === 'info' && <i className="material-icons md-36 text-success m-r-10">info</i>}
        {isType && notification.type === 'error' && (
          <i className="material-icons md-36 text-danger m-r-10">error_outline</i>
        )}
        {isType && notification.type === 'done' && <i className="material-icons md-36 text-success m-r-10">done</i>}
        {isType &&
          notification.type === 'confirm' &&
          notification.hasOwnProperty('callback') &&
          notification.callback !== null && (
            <i className="material-icons md-36 text-secondary m-r-10">playlist_add_check</i>
          )}
        {notification.heading}
      </ModalHeader>
      <ModalBody>
        {notification.displayText && (
          <p className={''} dangerouslySetInnerHTML={{ __html: notification.displayText }} />
        )}
      </ModalBody>
      <ModalFooter>
        {!notification.noCancel && !notification.callbackClose && <Button onClick={handleClose}>{closeText}</Button>}
        {!notification.noCancel && notification.callbackClose && <Button onClick={callbackClose}>{closeText}</Button>}
        {notification.callback && notification.callback !== null && (
          <Button onClick={callback} color="primary">
            {buttonOkText}
          </Button>
        )}
      </ModalFooter>
    </Modal>
  );
}

ModalBox.propTypes = {
  notification: PropTypes.object,
  updateNotification: PropTypes.func
};

export default ModalBox;
