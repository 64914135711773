import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ReactTable from 'react-table';
import selectTableHOC from 'react-table/lib/hoc/selectTable';
import 'react-table/react-table.css';
import './index.scss';
import i18n from 'i18n';

const SelectTable = selectTableHOC(ReactTable);

export default class BaseTable extends PureComponent {
  static propTypes = {
    columns: PropTypes.array,
    data: PropTypes.array,
    searchFields: PropTypes.array,
    searchText: PropTypes.string,
    showPagination: PropTypes.bool,
    sortedRules: PropTypes.array,
    showSelection: PropTypes.bool,
    searchData: PropTypes.func,
    sortedChange: PropTypes.func,
    filterCondition: PropTypes.object,
    resizable: PropTypes.bool,
    initialized: PropTypes.bool,
    onChangePage: PropTypes.func
  };

  static defaultProps = {
    columns: [],
    internalData: [],
    showPagination: true,
    sortedRules: [
      {
        id: 'updatedAt',
        desc: true
      }
    ],
    searchData: () => 'Not implement yet.',
    sortedChange: () => 'Not implement yet.',
    filterCondition: {},
    resizable: false
  };

  constructor(props) {
    super(props);
    this.state = {
      internalData: []
      // initialized: false
    };
  }

  componentDidMount() {
    this.setState({
      internalData: this.props.data,
      searchFields: this.props.searchFields
    });
  }

  componentWillReceiveProps(nextProps) {
    const { searchFields = [] } = this.state;
    const { searchText, filterCondition = {}, data } = nextProps;
    const searchTextInstance = searchText
      ? searchText
          .toString()
          .slice(0)
          .trim()
      : '';
    let internalData =
      Object.keys(filterCondition).length > 0 || searchTextInstance.length > 0
        ? data.filter(row => {
            const resultFilter = Object.keys(filterCondition || {}).every(filterKey => {
              if (filterCondition[filterKey]) {
                if (row[filterKey] === undefined) return true;
                if (row[filterKey]) {
                  return row[filterKey] === filterCondition[filterKey];
                } else return false;
              } else return true;
            });
            if (resultFilter && searchTextInstance.length > 0) {
              let textMatched = false;
              for (let i = 0; i < searchFields.length; i++) {
                const _searchField = searchFields[i];
                const _dataField = row[_searchField];
                if (_dataField) {
                  textMatched = _dataField.toLowerCase().indexOf(searchTextInstance.toLowerCase()) > -1;
                  if (textMatched) break;
                }
              }
              return textMatched;
            }
            return resultFilter;
          })
        : data;

    this.setState({
      internalData
    });

    if (
      searchText !== this.props.searchText ||
      JSON.stringify(filterCondition) !== JSON.stringify(this.props.filterCondition) ||
      JSON.stringify(data) !== JSON.stringify(this.props.data)
    ) {
      const filters = Object.values(filterCondition).filter(val => val);
      if (searchText || filters.length) {
        this.props.searchData(internalData);
      }
    }
  }

  /**
   * Align center for selection header
   */
  getTheadThProps = (state, rowInfo, column) => {
    if (column.id === '_selector') {
      return {
        style: {
          margin: 'auto'
        }
      };
    }
    return {};
  };

  /**
   * Remove border for padding row
   */
  getTrGroupProps = (state, rowInfo, column) => {
    if (!rowInfo) {
      return {
        style: {
          border: 'none'
        }
      };
    }
    return {};
  };

  handleShortChange = sorted => {
    this.props.sortedChange(sorted);
  };

  render() {
    const {
      columns,
      sortedRules,
      showSelection,
      initialized = true,
      showPagination,
      resizable,
      onChangePage,
      ...rest
    } = this.props;
    const { internalData } = this.state;
    const showPaging = showPagination && internalData.length > 0;
    const TableComp = showSelection ? SelectTable : ReactTable;
    const spin = (
      <div className="loadingTable">
        <div className="lds-ellipsis">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    );
    return (
      <TableComp
        keyField="id"
        {...rest}
        columns={columns}
        showPagination={showPaging}
        defaultPageSize={10}
        minRows={10}
        resizable={resizable}
        defaultSorted={sortedRules}
        noDataText={initialized ? i18n.t('table.no.available.data') : spin}
        previousText={i18n.t('table.prev')}
        nextText={i18n.t('table.next')}
        loadingText={i18n.t('table.loading')}
        pageText={i18n.t('table.page')}
        ofText={i18n.t('table.of')}
        rowsText={i18n.t('table.rows')}
        data={internalData}
        onFetchData={onChangePage}
        getTheadThProps={this.getTheadThProps}
        getTrGroupProps={this.getTrGroupProps}
        onSortedChange={this.handleShortChange}
      />
    );
  }
}
