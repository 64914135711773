import React, { PureComponent } from 'react';
import { Card, Button } from 'reactstrap';
import PropTypes from 'prop-types';
import { getResourceImage } from 'core/helpers';
import InfiniteScroll from 'react-infinite-scroller';
import i18n from 'i18n';
import './index.scss';

export default class CardTable extends PureComponent {
  static propTypes = {
    tasks: PropTypes.array,
    selectedTasks: PropTypes.array,
    onSelectCard: PropTypes.func,
    ableToSelect: PropTypes.bool
  };

  static defaultProps = {
    tasks: [],
    selectedTasks: [],
    onSelectCard: () => {},
    ableToSelect: false
  };

  constructor(props) {
    super(props);
    this.state = {
      loadTotal: 0,
      loadNum: 10,
      page: 0
    };
  }

  onClick = item => {
    if (this.props.ableToSelect) {
      this.props.onSelectCard(item.id);
    } else {
      this.props.onClickCard(item);
    }
  };

  loadFunc = pageNumber => {
    const count = parseInt(this.state.loadNum * pageNumber);
    this.setState({ loadTotal: count, page: pageNumber });
  };

  render() {
    const { tasks } = this.props;
    const { loadTotal, page } = this.state;
    return (
      <InfiniteScroll
        pageStart={page}
        loadMore={this.loadFunc}
        hasMore={tasks.length > loadTotal}
        loader={
          <div key={0} className="loader p-l-0 p-b-20 text-muted">
            {i18n.t('common.label.loading')}
          </div>
        }
      >
        <div className="row">
          {tasks.slice(0, this.state.loadTotal).map((item, index) => {
            const { serviceProviderType, environment } = item;
            const resourceImageType = ['lysithea_pc', 'lysithea_sp', 'time_sheet'].includes(serviceProviderType)
              ? 'PREVIEW' === environment
                ? `preview_${serviceProviderType}`
                : serviceProviderType
              : serviceProviderType;
            const hardIcon =
              '01G15MW3MG51KFX9ZGSMNJCK12' === item.id ? require('assets/images/providers/tci-pf.png') : null;
            return (
              <div className="col-xs-12 col-sm-4 col-md-3" key={`resource_card_index_${index}`}>
                <Card
                  className="card-default card-shadow text-center"
                  onClick={() => this.onClick(item)}
                  key={`resource_card_index_${index}`}
                >
                  <div className="card-img">
                    <img src={hardIcon || getResourceImage(resourceImageType)} alt={item.description} />
                  </div>
                  <div className="card-header">
                    <div className="card-title">{item.name}</div>
                  </div>
                  {item.action && item.action === 2 && (
                    <div className="access">
                      <div className="access-link">
                        <Button>
                          {i18n.t('common.label.access')} <i className="material-icons">launch</i>
                        </Button>
                      </div>
                      <div className="mask"></div>
                    </div>
                  )}
                </Card>
              </div>
            );
          })}
        </div>
      </InfiniteScroll>
    );
  }
}
