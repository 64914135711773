export const randomString = len =>
  Array(len)
    .fill('0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz')
    .map(function(x) {
      return x[Math.floor(Math.random() * x.length)];
    })
    .join('');

export const getResourceImage = type => {
  const empty = require('assets/images/providers/empty.png');
  const image = {
    wiki: require('assets/images/providers/wiki.png'),
    aws_mng_console: require('assets/images/providers/aws.png'),
    lysithea_pc: require('assets/images/providers/lysithea-pc.jpg'),
    lysithea_sp: require('assets/images/providers/lysithea-sp.jpg'),
    time_sheet: require('assets/images/providers/lysithea-timesheet.jpg'),
    preview_lysithea_pc: require('assets/images/providers/preview-lysithea-pc.jpg'),
    preview_lysithea_sp: require('assets/images/providers/preview-lysithea-sp.jpg'),
    preview_time_sheet: require('assets/images/providers/preview-lysithea-timesheet.jpg')
  };
  return image.hasOwnProperty(type) ? image[type] : empty;
};

export const validURL = str => {
  try {
    if (!str.startsWith('http://') && !str.startsWith('https://')) return false;

    new URL(str);
    return true;
  } catch (_) {
    return false;
  }
};

export const compareString = (a, b) => {
  const _a = a ? a.toLowerCase() : '';
  const _b = b ? b.toLowerCase() : '';
  if (_a < _b) return -1;
  if (_a > _b) return 1;
  return 0;
};

/* convert string from full to half width
 *		１２３ to 123
 *   'space' full to half width
 */
export const toHalfWidth = str => {
  const value = str
    .replace(/[\uff01-\uff5e]/g, ch => String.fromCharCode(ch.charCodeAt(0) - 0xfee0))
    .replace(/\u3000/g, '\u0020')
    .replace(/[ー−]/g, '\u002D');
  return value;
};
