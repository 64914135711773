import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import Profile from './forms/Profile';
import Password from './forms/Password';
import TwoStepAuthentication from './forms/TwoStepAuthentication';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ModalBox from 'components/ModalBox';
import { Loading } from 'element-react';

function ProfileSettings(props) {
  const { t } = useTranslation();
  const { notification, fullloading, currentUser } = props;
  const [activeTab, setActiveTab] = useState('1');
  const displayTabs = currentUser && currentUser.loginDomainProvider === 'email';
  const action = localStorage.getItem('action');
  if ('SETTING_MFA' === action) {
    setActiveTab('3');
    localStorage.removeItem('action');
  }

  useEffect(() => {}, []);

  function toggle(tab) {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  }

  return (
    <div className="card">
      <div className="card-header">
        <Nav tabs className="nav-tabs-simple reponsive-tab">
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '1' })}
              onClick={() => {
                toggle('1');
              }}
            >
              {t('common.profile.label')}
            </NavLink>
          </NavItem>
          {displayTabs && (
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === '2' })}
                onClick={() => {
                  toggle('2');
                }}
              >
                {t('common.password.label')}
              </NavLink>
            </NavItem>
          )}
          {displayTabs && (
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === '3' })}
                onClick={() => {
                  toggle('3');
                }}
              >
                {t('common.twoStep.auth.mfa.label')}
              </NavLink>
            </NavItem>
          )}
        </Nav>
      </div>
      <div className="card-body">
        <TabContent activeTab={activeTab} className="m-p-lr-0 p-r-20 p-l-20">
          <TabPane tabId="1">
            <Profile />
          </TabPane>
          <TabPane tabId="2">
            <Password />
          </TabPane>
          <TabPane tabId="3">
            <TwoStepAuthentication />
          </TabPane>
        </TabContent>
      </div>
      {notification.showModal && <ModalBox />}
      {fullloading && <Loading fullscreen={true} />}
    </div>
  );
}

export default ProfileSettings;
