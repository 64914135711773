import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { InputGroup, InputGroupText, InputGroupAddon, Input } from 'reactstrap';
import { pick } from 'lodash';
import TextCounter from 'components/TextCounter';
import './index.scss';
import { EMOJI_REGEX } from 'core/constants';

export default class BaseInput extends PureComponent {
  static propTypes = {
    name: PropTypes.string,
    type: PropTypes.string,
    placeholder: PropTypes.string,
    maxLength: PropTypes.number,
    value: PropTypes.string,
    disabled: PropTypes.bool,
    readOnly: PropTypes.bool,
    append: PropTypes.any,
    prepend: PropTypes.any,
    showCounter: PropTypes.bool,
    convertToHalfWidth: PropTypes.bool,
    customizeRegex: PropTypes.object,
    onChange: PropTypes.func,
    onKeyPress: PropTypes.func,
    preventAtSpecial: PropTypes.bool // prevent input charactor: @
  };
  static defaultProps = {
    showCounter: false,
    convertToHalfWidth: true,
    preventAtSpecial: false,
    value: '',
    customizeRegex: {},
    onChange: () => {}
  };
  constructor(props) {
    super(props);
    this.state = { internalValue: '' };
  }

  componentDidMount() {
    this.setState({
      internalValue: this.props.value || ''
    });
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      internalValue: nextProps.value || ''
    });
  }

  setValue(value) {
    this.setState(
      {
        internalValue: value
      },
      () => this.props.onChange(this.state.internalValue)
    );
  }

  onChange(event) {
    let { value } = event.target;
    // cover tabs, newlines, etc, just replace \s\s+ with ' '
    value = value.replace(/\s\s+/g, ' ');
    value = value.replace(/^\s+/g, '');
    // replace emoji
    value = value.replace(EMOJI_REGEX, '');
    // regex
    const { customizeRegex, preventAtSpecial } = this.props;
    const afterRegex = Object.keys(customizeRegex).every(key => customizeRegex[key].test(value));
    if (afterRegex) {
      // set state
      //Check input value don't allow input special characters
      if (preventAtSpecial && value) {
        value = value.replace(/@/g, '');
      }
      // Substring
      if (this.props.maxLength) {
        value = value.substr(0, this.props.maxLength);
      }

      this.setValue(value);
    }
  }

  onBlur() {
    let value = this.state.internalValue;
    // Convert full-width to half-width
    if (typeof value === 'string' && this.props.convertToHalfWidth) {
      value = value
        .replace(/[\uff01-\uff5e]/g, fullwidthChar => String.fromCharCode(fullwidthChar.charCodeAt(0) - 0xfee0))
        .replace(/\s+/g, ' ');
    }
    // Substring
    if (this.props.maxLength) {
      value = value.substr(0, this.props.maxLength);
    }
    // set state
    this.setValue(value);
  }

  onKeyPress(event) {
    if (event.key === 'Enter' && typeof this.props.onKeyPress === 'function') {
      event.preventDefault();
      this.props.onKeyPress(this.state.internalValue);
    }
  }

  render() {
    // variables
    const { append, prepend, maxLength, value } = this.props;
    const inputProps = {
      ...pick(this.props, ['name', 'type', 'placeholder', 'disabled', 'readOnly', 'style', 'rows'])
    };
    const showCounter = this.props.showCounter && maxLength && value;
    return (
      <InputGroup>
        {prepend && (
          <InputGroupAddon addonType="prepend">
            <InputGroupText>{prepend}</InputGroupText>
          </InputGroupAddon>
        )}
        <Input
          {...inputProps}
          value={this.state.internalValue}
          onChange={this.onChange.bind(this)}
          onBlur={this.onBlur.bind(this)}
          onKeyPress={this.onKeyPress.bind(this)}
        />
        {showCounter && (
          <InputGroupAddon addonType="append">
            <InputGroupText>
              <TextCounter data={value} max={maxLength} />
            </InputGroupText>
          </InputGroupAddon>
        )}
        {append && (
          <InputGroupAddon addonType="append">
            <InputGroupText>{append}</InputGroupText>
          </InputGroupAddon>
        )}
      </InputGroup>
    );
  }
}
