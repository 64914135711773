import { request } from 'core/request';

const getResources = async () => {
  const data = await request({
    method: 'get',
    url: '/dashboard/resources'
  });
  return data;
};

const getInitSaml = async (idpId, token) => {
  const data = await request(
    {
      method: 'get',
      url: `/saml/init-sso?idpid=${idpId}&token=${token}&type=json`
    },
    false
  );
  return data;
};

const getSamlApp = async (idpid, shouldAddAuthorizeToken = true) => {
  const data = await request(
    {
      method: 'get',
      url: `/saml/app?idpid=${idpid}`
    },
    shouldAddAuthorizeToken,
    false
  );
  return data;
};

const verifyPolicy = async resourceId => {
  const data = await request({
    method: 'post',
    url: `/dashboard/resources/${resourceId}/verifyPolicy`
  });
  return data;
};

export default {
  getResources,
  getInitSaml,
  getSamlApp,
  verifyPolicy
};
