import { combineReducers } from 'redux';
import notification from 'store/notificationSlice';
import loading from 'store/loadingSlice';
import user from 'store/userSlice';
import resource from 'store/resourceSlice';
import log from 'store/logSlice';
import section from 'store/sectionSlice';
import global from 'store/globalSlice';

export default combineReducers({
  notification,
  loading,
  user,
  resource,
  log,
  section,
  global
});
