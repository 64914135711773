const popup = url => {
  const windowArea = {
    width: Math.floor(window.outerWidth * 0.3),
    height: Math.floor(window.outerHeight * 0.8)
  };

  if (windowArea.width < 300) {
    windowArea.width = 300;
  }
  if (windowArea.height < 400) {
    windowArea.height = 400;
  }
  windowArea.left = Math.floor(window.screenX + (window.outerWidth - windowArea.width) / 2);
  windowArea.top = Math.floor(window.screenY + (window.outerHeight - windowArea.height) / 8);

  // const sep = url.indexOf('?') !== -1 ? '&' : '?';
  // const url = `${url}${sep}`;
  const windowOpts = `toolbar=0,scrollbars=1,status=1,resizable=1,location=1,menuBar=0,
    width=${windowArea.width},height=${windowArea.height},
    left=${windowArea.left},top=${windowArea.top}`;

  const authWindow = window.open(url, 'Sign in with AD', windowOpts);
  // Create IE + others compatible event handler
  const eventMethod = window.addEventListener ? 'addEventListener' : 'attachEvent';
  const eventer = window[eventMethod];
  const messageEvent = eventMethod === 'attachEvent' ? 'onmessage' : 'message';

  // Listen to message from child window
  const authPromise = new Promise((resolve, reject) => {
    eventer(
      messageEvent,
      e => {
        // console.log('eventer >>>> e: ', e);
        if (e.data.userToken) {
          authWindow.close();
          resolve(e.data);
        } else {
          authWindow.close();
          reject(e.data);
        }
      },
      false
    );
  });

  return authPromise;
};

export default popup;
