import { cookies, resetLocalStorage } from 'core/utils';
import { MODE_LOGIN } from 'core/constants';
import { auth as authService } from 'services';
import store from 'store';
import { getModeLogin } from 'store/globalSlice';

export const handleError = (err, toast, updateNotification, updateUser, history, t) => {
  if (
    err &&
    err.response &&
    err.response.data &&
    (err.response.data.code === 'token-expired' ||
      err.response.data.code === 'invalid-token' ||
      err.response.data.code === 'token-is-required')
  ) {
    const state = store.getState();
    const modeLogin = getModeLogin(state);
    updateNotification({
      showModal: true,
      heading: t('common.message.warning.header'),
      noCancel: true,
      displayText: t('common.message.sessionExpired'),
      type: 'warning',
      buttonOkText: t('common.button.yes'),
      buttonCloseText: t('common.button.no'),
      callback: async closeModal => {
        closeModal();
        updateUser(null);
        cookies.removeAllTokens();
        resetLocalStorage();
        history.push('/tci');
      }
    });
  } else {
    toast.error(t('common.message.error'));
  }
};

export default {
  handleError
};
